import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Helmet } from "react-helmet"

import { component as Footer } from "./footer"
import { component as Header } from "./header"
import { DebugMediaQueries } from "@plugins/gatsby-ample-debuggers"
import SEO from "@layout/seo"
import TriangleBackground from "@layout/triangle-background"

const Layout = ({ children, className, menu, seoMetaTags, footer, useTriangleBackground }) => (
  <>
    <Helmet>
      <html lang="en" />
    </Helmet>
    <SEO metaTags={seoMetaTags.tags} />
    <Header {...menu} />
    <main
      className={classNames({
        [className]: className
      })}
    >
      {children}
    </main>
    <Footer {...footer} />
    <TriangleBackground shouldDisplay={useTriangleBackground} />
    <DebugMediaQueries isShowing={process.env.GATSBY_DEBUG_MEDIA_QUERIES} />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Additional classes on the main element.
   */
  className: PropTypes.string,

  /**
   * The footer content
   */
  footer: PropTypes.object,

  /**
   * The menu for the header
   */
  menu: PropTypes.shape({
    links: PropTypes.array
  }),

  /**
   * SEO Meta Tags for the page
   */
  seoMetaTags: PropTypes.shape({
    tags: PropTypes.array
  }),

  /**
   * If the triangle background should be used.
   */
  useTriangleBackground: PropTypes.bool
}

Layout.defaultProps = {
  seoMetaTags: {
    tags: []
  },
  useTriangleBackground: false
}

export default Layout
