import React from "react"
import Layout from "@layout"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Grid from "@layout/grid"

import * as styles from "../templates/page/contact/styles.module.scss"

class ContactThankYouPage extends React.Component {
  page = this.props.data.datoCmsContactPageTruckVan

  render() {
    const bodyContent =
      this.page.bodyNode && this.page.bodyNode.childMarkdownRemark
        ? this.page.bodyNode.childMarkdownRemark.html
        : this.page.body

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>

        <Layout
          seoMetaTags={this.page.seoMetaTags}
          useTriangleBackground={true}
          menu={this.props.data.menu}
          footer={this.props.data.footer}
        >
          <div className={styles.contactPage}>
            <h1>{this.page.title}</h1>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: bodyContent
              }}
            />
            <div className={styles.gridContent}>
              <Grid items="2" hasChildren gutter="15" layout="2">
                <div>
                  <div className={styles.success}>
                    <h2>Thank you</h2>
                    <p>We will respond to you soon.</p>
                  </div>
                </div>

                <div>
                  <div className={styles.iframe_wrapper}>
                    <iframe
                      title="Google Maps"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3089.4723800894058!2d-84.44186074912201!3d39.25484733329949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88404df5c2bfffff%3A0x7718db175b6bfa65!2s10381%20Evendale%20Dr%20%23100%2C%20Cincinnati%2C%20OH%2045241!5e0!3m2!1sen!2sus!4v1634741320661!5m2!1sen!2sus"
                      width="600"
                      height="450"
                      style={{ border: "0" }}
                      allowFullScreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                  <h3>Mike Albert Truck &amp; Van Equipment</h3>
                  {this.page.address && (
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${this.page.address}`}
                      rel="noreferrer"
                    >
                      {this.page.address}
                    </a>
                  )}
                  {this.page.phoneNumber && (
                    <a href={`tel:${this.page.address.replace(/-/g, "")}`}>
                      {this.page.phoneNumber}
                    </a>
                  )}
                  <h4>Hours</h4>
                  <div className={styles.hours}>
                    {this.page.hours.map((hour, index) => {
                      return (
                        <div key={index}>
                          <p>{hour.daysOfWeek}</p>
                          <p>{hour.times}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default ContactThankYouPage

export const query = graphql`
  query ContactThankYouPageQuery {
    ...MegaMenu
    ...Footer

    datoCmsContactPageTruckVan {
      permalink
      title
      body
      seoMetaTags {
        tags
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      address
      phoneNumber
      hours {
        times
        daysOfWeek
      }
    }
  }
`
